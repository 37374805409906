<template>
	<div>
		<div class="content">
			<div class="mb20 pageTitle"><b>配置话单时间</b></div>
			<div>
				<el-form class="form" label-width="120px" v-loading="loading">
					<el-form-item label="未使用:">
						<el-input prefix-icon="el-icon-time" type="number" placeholder="话单未使用，在系统中停留的最大时间" v-model="not_used_timeout"></el-input>
					</el-form-item>
					<el-form-item label="生产链接:">
						<el-input prefix-icon="el-icon-time" type="number" placeholder="使用话单生产付款链接的时间" v-model="create_payurl_timeout"></el-input>
					</el-form-item>
					<el-form-item label="等待配单:">
						<el-input prefix-icon="el-icon-time" type="number" placeholder="付款链接等待客户匹配的时间" v-model="payurl_wait_timeout"></el-input>
					</el-form-item>
					<el-form-item label="用户支付:">
						<div class="flexX">
							<el-input prefix-icon="el-icon-time" type="number" placeholder="匹配给客户后的付款时间" v-model="user_pay_timeout" clearable></el-input>
						</div>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				not_used_timeout:0,
				create_payurl_timeout:0,
				payurl_wait_timeout:0,
				user_pay_timeout:0,
			}
		},
		created() {
			this.getPbmConfigInfo()
		},
		methods: {
			onSubmit(){
				if(isEmpty(this.not_used_timeout,"未使用不能为空")){
					return
				}
				if(isEmpty(this.create_payurl_timeout,"生产链接不能为空")){
					return
				}
				if(isEmpty(this.payurl_wait_timeout,"等待配单不能为空")){
					return
				}
				if(isEmpty(this.user_pay_timeout,"用户支付不能为空")){
					return
				}
				
				let data={
					not_used_timeout:this.not_used_timeout,
					create_payurl_timeout:this.create_payurl_timeout,
					payurl_wait_timeout:this.payurl_wait_timeout,
					user_pay_timeout:this.user_pay_timeout,
				}
				this.loading = true;
				this.$api.setPbmConfig(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						this.getPbmConfigInfo()
						this.$message({type:"success",message:"设置成功"})
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.loading = false
				})
			},
			getPbmConfigInfo(){
				
				let data={
				}
				this.$api.getPbmConfigInfo(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						 res.data.forEach(item => {
						                if (item.name === "not_used_timeout") {
						                    this.not_used_timeout = item.value;
						                }
						                if (item.name === "create_payurl_timeout") {
						                    this.create_payurl_timeout = item.value;
						                }
						                if (item.name === "payurl_wait_timeout") {
						                    this.payurl_wait_timeout = item.value;
						                }
						                if (item.name === "user_pay_timeout") {
						                    this.user_pay_timeout = item.value;
						                }
						            });
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.loading = false
				})
			}
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}
	
		.tips {
			width: 460px;
		}
	
		.form {
			width: 460px;
		}
	}
</style>
